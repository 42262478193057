<template>
    <div class="box">
        <h1 class="title  has-text-centered"><span class="is-size-4">the</span> Quarry</h1>
        
        <div class="columns">
            <div class="column is-one-fifth">
                <figure class="image is-128x128">
                    <img src="images/3001.png">
                </figure>
            </div>
            <div class="column">
                <div class="block">Welcome to the Quarry - Your Ultimate Selling Companion for BrickLink! Unlock the full potential of your BrickLink store with the Quarry! Whether you're a seasoned seller or just starting out, our platform is designed to streamline your selling experience and maximize your profits.</div>
            </div>
        </div>
        <div class="block">We love bricklink and have been buy and selling on it for years. It does an amazing job but it is focused on buyers, as it should be. If you've had to dd lots of items in lots of colours, you know it can take some time, going into each colour, seeing the price history, most of the time setting a price in a similar way (e.g. the average price), remembering that as you click through a few screens, sometime setting a standard comment, and doing that again for another 10 colours. The time adds up.</div> 
        <div class="block">The basics of this tool is to try and automate what can be automated, see all the information in a sinlge spot and at best give you a single click to add update on a single screen.</div> 
        <div class="block">Beyound that, we also aim to alert you to to abnolies and opportunities in your inventory, things that seel quickly, may be over or under priced etc and give you analytics on your store.</div> 
    </div>    
      
</template>to 

<style scoped>


.stat-type {
    font-size: 16px;
    font-weight: bold;
}

.title {
    font-size: 48px;
    font-weight: bold;
}

.subtitle {
    font-size: 16px;
    
}

h1 {
    margin-top: 0px;
}
</style>

<script>
export default {
  name: 'HomeInfoBox',
  props: {
    ColourTheme: String
  },
  
}
</script>

