<template>
  <footer class="footer is-small">
    <div class="content has-text-centered">
      <p  class="small">
        LEGO, Bricklink and the LEGO logo are trademarks of The LEGO Group of companies which is not associated with the Quarry.
      </p>
      <p  class="small">
        <!-- <router-link to="/termsandconditions">terms and conditions</router-link> |  -->
        <!-- <router-link to="/privacypolicy">privacy policy</router-link> |  -->
        <router-link to="/fees">fees</router-link> | 
        <router-link to="/contactus">contact us</router-link>
      </p>
    </div>
  </footer>
</template>



